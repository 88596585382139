
import { Box, Button, ButtonGroup, Grid } from '@mui/material';
import { Link } from 'react-router-dom';


function Navigation() {
    return (
        <>
            <Box sx={{ p: 1, backgroundColor: '#fff' }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <img style={{ height: '80px' }} src="/express-logo.png" />
                    </Grid>
                    <Grid item>
                        <ButtonGroup variant="outlined">
                            <Button component={Link} to="/">
                                Home
                            </Button>
                            <Button component={Link} to="/pricing">
                                Pricing
                            </Button>
                            <Button component={Link} to="/privacy">
                                Privacy
                            </Button>
                            <Button component={Link} to="/terms">
                                Terms
                            </Button>
                            <Button component={Link} to="/contact">
                                Contact
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default Navigation;