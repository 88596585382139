import { Box, Container, CssBaseline, Paper } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Routes, Route } from "react-router-dom";
import './App.css';
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import Contact from "./routes/Contact";
import Home from "./routes/Home";
import Pricing from "./routes/Pricing";
import Privacy from "./routes/Privacy";
import Terms from "./routes/Terms";
import WhatsAppButton from "./components/WhatsAppButton";

const darkTheme = createTheme({
  typography: {
    fontFamily: "'Roboto', sans-serif"
  },
  palette: {
    primary: {
        "50": "#f5f5f5",
        "100": "#e9e9e9",
        "200": "#d9d9d9",
        "300": "#c4c4c4",
        "400": "#555555",
        "500": "#434343",
        "600": "#434343",
        "700": "#434343",
        "800": "#262626",
        "900": "#000000"
      },
    divider: grey[200],
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ bgcolor: 'background.default' }} className="header">
        <Container>
          <Header />
          <Navigation />
        </Container>
      </Box>
      <Container>
        <Paper elevation={0} >
          <Container>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="pricing" element={<Pricing />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="terms" element={<Terms />} />
              <Route path="contact" element={<Contact />} />
            </Routes>
          </Container>
        </Paper>
        <Box sx={{ textAlign: 'center', fontSize: '11px', py: 4, fontStyle: 'italic' }}>
          &#169; {new Date().getFullYear()} express-transfer.co.uk
        </Box>
      </Container>
      <WhatsAppButton />
    </ThemeProvider>
  );
}

export default App;
