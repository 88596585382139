import { Box, Grid, Typography } from "@mui/material";

function Pricing() {
    return (
        <>

            <Box sx={{ pt: 4, textAlign: 'left' }}>
                <Grid container>
                    <Grid md={12} item>
                        <Typography sx={{ pb: 3 }} variant="h5" component="h1">
                            Price
                        </Typography>

                        <Grid container>
                            <Grid md={12} item>
                                <p><strong><span lang="EN-GB">Premium Airport Transfers and Minibus Hire on the Wirral &ndash; Express Transfer</span></strong></p>
                                <p><span lang="EN-GB">Welcome to <strong>Express Transfer</strong>, your top choice for <strong>Mercedes Executive Airport Transfers</strong> across <strong>Wirral</strong>. We specialize in providing luxury transportation for <strong>airport and seaport transfers</strong>, corporate travel, and special events. Whether you're traveling for business or leisure, our premium fleet ensures you arrive in comfort and style.</span></p>
                                <p><span lang="EN-GB">Simply <strong>fill out our booking form</strong> to receive a <strong>competitive quote within minutes</strong>!</span></p>
                                <h3><span lang="EN-GB">Fixed Prices for Airport Transfers</span></h3>
                                <p><span lang="EN-GB">Our airport transfer prices are <strong>fixed and include all applicable fees</strong> such as tunnel tolls, parking, and waiting time. Prices are displayed after specifying the <strong>travel destinations</strong> on the first page. <strong>Additional pick-ups</strong> within the Wirral area are available for an extra &pound;5 each please leave a note during the booking for additional pick-ups</span></p>
                            </Grid>
                            <Grid md={7} item>
                                <Box style={{ background: '#ead159', padding: '30px', borderRadius: '40px' }}>
                                    <h3>Popular Airport Transfer Rates:</h3>
                                    <ul>
                                        <li><strong>Manchester Airport:</strong></li>
                                    </ul>
                                    <ul>
                                        <ul>
                                            <li>1 to 3 passengers: from <strong>&pound;70</strong> each way (estate car)</li>
                                            <li>4 to 5 passengers: from <strong>&pound;85</strong> each way (minibus)</li>
                                            <li>6 to 8 passengers: from <strong>&pound;100</strong> each way (minibus)</li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li><strong>Liverpool John Lennon Airport:</strong></li>
                                    </ul>
                                    <ul>
                                        <ul>
                                            <li>1 to 3 passengers: from <strong>&pound;55</strong> each way (estate car)</li>
                                            <li>4 to 5 passengers: from <strong>&pound;70</strong> each way (minibus)</li>
                                            <li>6 to 8 passengers: from <strong>&pound;80</strong> each way (minibus)</li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li><strong>Leeds Bradford Airport:</strong></li>
                                    </ul>
                                    <ul>
                                        <ul>
                                            <li>1 to 3 passengers: from <strong>&pound;190</strong> each way (estate car)</li>
                                            <li>4 to 5 passengers: from <strong>&pound;200</strong> each way (minibus)</li>
                                            <li>6 to 8 passengers: from <strong>&pound;220</strong> each way (minibus)</li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li><strong>Birmingham Airport:</strong></li>
                                    </ul>
                                    <ul>
                                        <ul>
                                            <li>1 to 3 passengers: from <strong>&pound;220</strong> each way (estate car)</li>
                                            <li>4 to 5 passengers: from <strong>&pound;240</strong> each way (minibus)</li>
                                            <li>6 to 8 passengers: from <strong>&pound;260</strong> each way (minibus)</li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li><strong>London Heathrow Airport:</strong></li>
                                    </ul>
                                    <ul>
                                        <ul>
                                            <li>1 to 3 passengers: from <strong>&pound;450</strong> each way (estate car)</li>
                                            <li>4 to 5 passengers: from <strong>&pound;475</strong> each way (minibus)</li>
                                            <li>6 to 8 passengers: from <strong>&pound;500</strong> each way (minibus)</li>
                                        </ul>
                                    </ul>
                                    <p><strong>Other airports:</strong> For transfers to additional airports, please <strong>contact us directly</strong> at</p>
                                    <p>Landline:<strong>0151 3386405</strong> or <strong>message us on </strong></p>
                                    <p><strong>Mobile:07554745924</strong>.</p>
                                </Box>
                            </Grid>
                            <Grid md={5} item>
                                <Box style={{ padding: '30px', borderRadius: '40px' }}>

                                    <h3>Sporting Event Transfers</h3>
                                    <p>We also offer fixed rates for transfers to popular <strong>sporting venues</strong>:</p>
                                    <ul>
                                        <li><strong>Anfield Stadium or Goodison Park:</strong></li>
                                    </ul>
                                    <ul>
                                        <ul>
                                            <li>1 to 4 passengers: <strong>&pound;40</strong></li>
                                            <li>5 to 8 passengers: <strong>&pound;50</strong></li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li><strong>Etihad Stadium &amp; Old Trafford Stadium:</strong></li>
                                    </ul>
                                    <ul>
                                        <ul>
                                            <li>1 to 4 passengers: <strong>&pound;110</strong></li>
                                            <li>5 to 8 passengers: <strong>&pound;130</strong></li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li><strong>Chester Racecourse:</strong></li>
                                    </ul>
                                    <ul>
                                        <ul>
                                            <li>1 to 4 passengers: <strong>&pound;50</strong></li>
                                            <li>5 to 8 passengers: <strong>&pound;60</strong></li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li><strong>Aintree Racecourse:</strong></li>
                                    </ul>
                                    <ul>
                                        <ul>
                                            <li>1 to 4 passengers: <strong>&pound;60</strong></li>
                                            <li>5 to 8 passengers: <strong>&pound;70</strong></li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li><strong>Haydock Racecourse:</strong></li>
                                    </ul>
                                    <ul>
                                        <ul>
                                            <li>1 to 4 passengers: <strong>&pound;90</strong></li>
                                            <li>5 to 8 passengers: <strong>&pound;110</strong></li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li><strong>Cheltenham Racecourse:</strong></li>
                                    </ul>
                                    <ul>
                                        <ul>
                                            <li>1 to 4 passengers: <strong>&pound;300</strong></li>
                                            <li>5 to 8 passengers: <strong>&pound;350</strong></li>
                                        </ul>
                                    </ul>
                                    <p><strong>Note:</strong> All prices are based on <strong>1 pick-up within the Wirral area</strong>.</p>
                                </Box>
                            </Grid>
                            <Grid md={12} item>
                                <Box style={{ padding: '30px', borderRadius: '40px' }}>
                                    <Grid container>
                                        {[1, 2, 3, 4].map((i) => <Grid sm={6} md={3} item>
                                            <Box style={{ padding: '5px' }}>
                                                <img style={{ width: '100%' }} src={`/pricing-images/p_${i}.jpg`} />

                                            </Box>
                                        </Grid>)}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid md={12} item>
                                <Box style={{ padding: '30px', borderRadius: '40px' }}>
                                    <h3>What We Offer Free of Charge:</h3>
                                    <ul>
                                        <li><strong>Meet &amp; Greet Service</strong></li>
                                        <li><strong>Complimentary Bottled Water</strong></li>
                                        <li><strong>Bread &amp; Milk</strong> upon request (for returning travelers)</li>
                                        <li><strong>Child Car Seats</strong> available, or you can use your own, and we will store them while you are away</li>
                                        <li><strong>Device Charging Cables</strong> (Apple &amp; Android)</li>
                                        <li><strong>230v Inverter</strong> for laptop use</li>
                                    </ul>
                                    <h3>Areas We Serve</h3>
                                    <p>We provide <strong>Executive Airport Transfers</strong> from numerous locations across <strong>Wirral</strong>, including but not limited to:</p>
                                    <ul>
                                        <li><strong>Wirral, Cheshire, Merseyside, Liverpool, Barnston</strong><strong>, <strong>Chester</strong>, <strong>Ellesmere Port</strong>, <strong>Little Sutton</strong>, <strong>Bebington</strong>, <strong>Bromborough</strong>, <strong>Heswall</strong>, <strong>West Kirby</strong>, <strong>Wallasey</strong>, Eastham, Neston,&nbsp; <strong>Hoylake</strong>, <strong>Birkenhead</strong>, <strong>Upton</strong>, Prenton, Caldy, Mold, Flint, Barnston,</strong><strong> Great Sutton, </strong><strong>Helsby, </strong><strong>&nbsp;Beechwood, </strong><strong>Bidston, </strong><strong>Brimstage, </strong><strong>Bromborough, </strong><strong>Bromborough Pool, </strong><strong>Clatterbridge</strong></li>
                                        <li><strong>Claughton, </strong><strong>Egremont, </strong><strong>Frankby, </strong><strong>Gayton, </strong><strong>Greasby, </strong><strong>Irby, </strong><strong>&nbsp;Landican, </strong><strong>&nbsp;Larton, </strong><strong>&nbsp;Leasowe, </strong><strong>&nbsp;Liscard, </strong><strong>Wirral Boroug, Meols, </strong><strong>Moreton, </strong><strong>New Brighton, </strong><strong>New Ferry, </strong><strong>Newton, </strong><strong>Noctorum, </strong><strong>&nbsp;Oxton, </strong><strong>Pensby, </strong><strong>Port Sunlight, </strong><strong>Poulton, </strong><strong>Prenton, </strong><strong>Raby, </strong><strong>Rock Ferry, </strong><strong>&nbsp;Saughall Massie, </strong><strong>Seacombe, </strong><strong>Spital, Storeton, </strong><strong>Thingwall, </strong><strong>Thornton Hough, </strong><strong>Thurstaston, </strong><strong>Tranmere, </strong><strong>Upton, </strong><strong>Wallasey, </strong><strong>Wallasey Village, </strong><strong>West Kirby, </strong><strong>Woodchurch, </strong><strong>Woodside, </strong><strong>Raby Mere, Manchester, London, Birmingham, Leeds, Bangor</strong></li>
                                    </ul>
                                    <p>For a full list, visit our <strong>service areas section</strong> on the website.</p>
                                </Box>
                            </Grid>
                            <Grid md={12} item>
                                <Box style={{ padding: '30px', borderRadius: '40px' }}>
                                    <Grid container>
                                        {[5, 6, 7, 8].map((i) => <Grid sm={6} md={3} item>
                                            <Box style={{ padding: '5px' }}>
                                                <img style={{ width: '100%' }} src={`/pricing-images/p_${i}.jpg`} />

                                            </Box>
                                        </Grid>)}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid md={12} item>
                                <Box style={{ padding: '30px', borderRadius: '40px' }}>
                                    <h3>Additional Services</h3>
                                    <p>We also specialize in the following:</p>
                                    <ul>
                                        <li><strong>Corporate Executive Travel</strong></li>
                                        <li><strong>Train &amp; Bus Station Transfers</strong></li>
                                        <li><strong>Race Day Events</strong></li>
                                        <li><strong>8-Seater Minibus Hire</strong> for <strong>Night Outs</strong>, <strong>Stag &amp; Hen Parties</strong>, or other <strong>Special Occasions</strong></li>
                                        <li><strong>Airport transfer from Bangor university</strong></li>
                                    </ul>
                                    <p>Our <strong>Wirral Minibus Hire</strong> is perfect for journeys to locations like <strong>Liverpool John Lennon Airport</strong>, <strong>Manchester Airport</strong>, <strong>Chester Races</strong>, <strong>Haydock Races</strong>, <strong>Aintree Races</strong>, <strong>Blackpool</strong>, <strong>Southport</strong>, and more.</p>
                                    <h3>Why Choose Express Transfer?</h3>
                                    <p>At <strong>Express Transfer</strong>, we strive to provide a <strong>smooth and reliable travel experience</strong>. Book your transfer today and travel in style with our <strong>Mercedes Executive Transfers</strong>.</p>
                                    <p>For bookings and inquiries, <strong>call us on 0151 3386405</strong> or <strong>message\Whatsapp us at 07554745924</strong>.</p>
                                </Box>
                            </Grid>
                        </Grid>


                    </Grid>

                </Grid>

            </Box>
        </>
    );
}


export default Pricing;