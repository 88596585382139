import { Box, Grid, Typography } from "@mui/material";

function Contact() {
    return (
        <>

            <Box sx={{ pt: 4, textAlign: 'left' }}>
                <Grid container>
                    <Grid md={12} item>
                        <h3>Contact</h3>

                        <p><strong>Contact Express Transfer - Your Premium Airport Transfer Solution on the&nbsp; Wirral</strong></p>
                        <p>Looking for <strong>reliable and professional airport transfers</strong> in <strong>Wirral, Cheshire, Liverpool</strong>, or <strong>Merseyside</strong>? <strong>Contact Express Transfer</strong> today! We offer <strong>top-quality transfers</strong> for <strong>airport runs, seaport connections, corporate travel</strong>, and <strong>special events</strong>. Reach out to us using any of the options below, and we&rsquo;ll get back to you promptly with the best travel solution tailored to your needs.</p>
                        <h3>How to Contact Us</h3>
                        <ul>
                            <li><strong>Landline</strong>: <strong>0151 338 6405</strong></li>
                            <li><strong>Mobile Text/WhatsApp</strong>: <strong>+44 7554 745924</strong></li>
                            <li><strong>Email</strong>: info@airporttransfers.express</li>
                            <li><strong>Website</strong>: <a href="http://airporttransfers.express">http://airporttransfers.express</a></li>
                        </ul>
                        <p>For any <strong>lost items</strong>, please fill out our <a href="https://form.jotform.com/242586897095375">Lost and Found Form</a>. To share your feedback or file a complaint, use our <a href="https://form.jotform.com/242587217289367">Customer Complaint Form</a>.</p>
                        <h3>Get in Touch Today!</h3>
                        <p>If you want to <strong>book a transfer</strong>, discuss your travel needs, or just have a question, <strong>contact us</strong>! It's easy &ndash; <strong>send us an email, give us a call</strong>, or <strong>fill out the contact form</strong> on this page. Please provide your <strong>name, contact details,</strong> and any <strong>relevant information</strong> regarding the service you need. We will respond promptly to <strong>help you find the perfect travel solution</strong>.</p>
                        <p>Not sure about the details? <strong>No problem!</strong> We are more than happy to guide you through your options and assist you in selecting the <strong>best service</strong> to suit your <strong>requirements and budget</strong>.</p>
                        <h3>Why Choose Express Transfer?</h3>
                        <p>At <strong>Express Transfer</strong>, we guarantee <strong>smooth, professional,</strong> and <strong>comfortable transfers</strong> across the region. With us, you can be assured of:</p>
                        <ul>
                            <li><strong>Reliable Airport &amp; Seaport Transfers</strong>: Including <strong>Liverpool John Lennon Airport</strong>, <strong>Manchester Airport</strong>, and transfers to <strong>Bangor University</strong>.</li>
                            <li><strong>Corporate Executive Travel</strong>: Ideal for <strong>business trips</strong>, ensuring a <strong>luxurious and stress-free journey</strong>.</li>
                            <li><strong>Sporting &amp; Race Day Transfers</strong>: Travel in comfort to <strong>Anfield Stadium</strong>, <strong>Old Trafford</strong>, <strong>Chester Races</strong>, <strong>Haydock</strong>, <strong>Aintree</strong>, <strong>Cheltenham</strong>, and more.</li>
                            <li><strong>Special Occasion Transfers</strong>: Including <strong>weddings</strong>, <strong>parties</strong>, <strong>stag and hen dos</strong>, and <strong>night outs</strong>.</li>
                            <li><strong>Minibus Hire</strong>: 8-seater <strong>minibuses available</strong> for <strong>group travel</strong>, night outings, or special trips.</li>
                        </ul>
                        <h3>Service Areas</h3>
                        <p>We proudly serve areas across <strong>Wirral</strong>, <strong>Cheshire</strong>, <strong>Liverpool</strong>, and <strong>Merseyside</strong>. Our airport transfer services cover a wide range of destinations including:</p>
                        <ul>
                            <li><strong>Wirral Minibus Hire</strong>: Perfect for <strong>airport transfers</strong>, <strong>seaport transfers</strong>, and <strong>event travel</strong>. Whether you're heading to <strong>Manchester Airport</strong>, <strong>Liverpool Airport</strong>, <strong>Blackpool</strong>, <strong>Southport</strong>, or even <strong>Chester</strong>, our <strong>Mercedes Executive fleet</strong> guarantees a <strong>luxurious travel experience</strong>.</li>
                        </ul>
                        <h3>Book Your Transfer Today!</h3>
                        <p>For a <strong>premium airport transfer</strong> experience, <strong>contact Express Transfer</strong> today. We offer a variety of <strong>transportation services</strong> to meet every need, whether for <strong>business or leisure</strong>. With our <strong>fixed pricing</strong> and all-inclusive services, <strong>comfort and reliability</strong> are always guaranteed.</p>

                    </Grid>

                </Grid>

            </Box>
        </>
    );
}


export default Contact;