import { Box, Grid, Typography } from "@mui/material";

function Terms() {
    return (
        <>

            <Box sx={{ pt: 4, textAlign: 'left' }}>
                <Grid container>
                    <Grid md={12} item>

                        <p><strong>TERMS &amp; CONDITIONS</strong></p>
                        <p>These terms and conditions and the web-based booking form constitute the entire Agreement concerning the provision of Taxi service (&ldquo;Service&rdquo;) between you and Express-Transfer airport transfer . Completion of the booking and use of the Service indicates your unconditional acceptance of the terms and conditions set out in this Agreement.</p>
                        <ol>
                            <li>Our company may change these terms and conditions at any time. Existing bookings will be at the rate booking is made.</li>
                            <li>We request a minimum of 3 hours notice for any online bookings. If your booking is less than 3 hours, please call our office to check availability.</li>
                            <li>You must allow sufficient time when booking your taxi to allow for the check-in times required by your airline and for any delays caused by traffic conditions. We shall not be responsible for any delay caused by your failure to allow enough time to reach your destination or if the passengers are not ready for collection at the booked time.</li>
                            <li>You must order a suitable car size for the number of passengers and luggage. Our company cannot guarantee to carry excessive amounts of luggage.</li>
                            <li>You must supply all information required on the booking form, please use the special notes box to inform us of anything.</li>
                            <li>If there are any changes or variations including extra mileage extra waiting time or deviations to the journey other than what was agreed at the time of booking, the client will be charged extras in accordance with our pricing structure.</li>
                            <li>Incoming flights allows 45 mins free waiting time after which waiting charges will apply. Please allow enough time for immigration &amp; baggage to avoid unnecessary waiting charges.</li>
                            <li>For airport pick up our driver will wait up to 90 minutes, without having made contact with the passenger. If after 90 minutes driver still has not made contact with passenger, this will result as a no show &amp; no refund will be offered.</li>
                            <li>If you need to cancel your booking, please contact Express Transfer</li>
                            <li>&nbsp;as soon as possible. We will be happy to cancel &amp; refund a booking as long as we have 24 hours notice. If you cancel a booking after the vehicle has been dispatched then a charge will be incurred; the charge will be based on the distance/time that the allocated driver has travelled/spent prior to the point of cancellation.</li>
                            <li>We take complaints very seriously and investigate every complaint thoroughly. Please email or call us quoting your reference number and as much information as possible.</li>
                            <li>At times our company may use sub contractors/ partner company to cover a journey.</li>
                            <li>You shall be responsible for the behaviour of all the passengers in the car during the journey. You will be charged to cover cleaning costs in the unlikely event of the vehicle being soiled by any passenger. A full receipt can be emailed direct to you or a hand written business card size receipt can be supplied by your driver. *Please note that an emailed receipt can take up to two working days if asked for on the day of travel to your driver*</li>
                            <li>Our company have the right to refuse to carry any passenger who is thought to be under the influence of alcohol or drugs.</li>
                            <li>Our company will refuse or terminate any booking with immediate effect if your behaviour possess any driver or vehicle at risk of damage, violence or abuse by you or any passenger in your party. All passengers will be asked to vacate the vehicle as soon as it is safe to do so. No refund will be given.</li>
                            <li>Our company will track all incoming flights, to ensure our driver reaches you at your specified time.</li>
                            <li>Whilst we do our utmost to ensure our drivers are punctual and arrive on time, you will understand that we cannot accept responsibility for delays caused by circumstances beyond our control.</li>
                            <li>Our company shall use all reasonable endeavours to get you to your destination on time, but shall not be liable for any loss due to delays caused by road or traffic conditions beyond its control on the journey.</li>
                            <li>Please note that a child, no matter what age, counts as one passenger. If a child seat is required please inform the office at the time of booking. We will try our utmost best to provide a baby / booster seat Or if you have your own child seat you are most welcome to bring it. Please note that the installation of the child seat must be carried out by you.</li>
                            <li>If the driver is stuck in traffic or for any other reasons cannot reach in time at the point of collection, we will try to provide you with a car from one of our partners.</li>
                            <li>In the event that our driver does not show up at the scheduled time for collection, please contact our office. If you leave the pickup point without informing us we will not be liable for any compensation to you. If you take a minicab or a black cab from another company without our consent we will not be liable to pay you the price for it or refund you.</li>
                            <li>We reserve the right to change without notice, your Minicab, Taxi, and Chauffeur or Chauffeur driver at any time if necessary. Our company maintains a strict non-smoking policy in all its vehicles. Eating, drinking and smoking are not permitted in our vehicles.</li>
                            <li>All our vehicles are fully insured for passenger and third party claims. However, customer&rsquo;s properties are carried entirely at their own risk.</li>
                            <li>Clients are responsible for ensuring that their luggage is loaded/unloaded at all times. Our company or our sub contractors shall not be held responsible/liable for any loss/damage to such property.</li>
                            <li>If the car breaks down during your journey, our company will endeavour to arrange an alternative car to complete the journey as soon as possible.</li>
                            <li>Our company shall be entitled to cancel all services and provide refunds in the event of a declared national emergency, riot, war, fuel shortage, extreme weather or terrorist attack, or other circumstances beyond its control.</li>
                            <li>It is illegal to make a private booking with our drivers. Our company will not be liable for any situation faced by the passenger while travelling on a booking not confirmed with the office.</li>
                        </ol>
                        <p>Please note that some calls may be recorded for quality and training purposes.</p>
                        <p>&nbsp;</p>
                        <p>Express Transfer&nbsp;</p>
                        <p>12 Woodend Road , Overpool, Cheshire West CH653BB</p>
                        <p>&nbsp;</p>

                    </Grid>

                </Grid>

            </Box>
        </>
    );
}


export default Terms;