import { Box, Grid, Typography } from "@mui/material";
import GoogleReviews from "../components/GoogleReviews";

function Home() {
    return (
        <>
            <Box sx={{ pt: 4, textAlign: 'center' }}>
                <Grid container>
                    <Grid md={12} item>
                        <Box sx={{ pb: 2 }}>
                            <img style={{ width: '100%' }} alt="cover" src="/cover.gif" />
                        </Box>
                    </Grid>

                    <Grid xs={12} item>
                        <Box sx={{ pb: 5 }}>
                            <iframe
                                src="https://yourwebbooker.com/en/6641a25b2e93413efaa4c1a6/false/true/true/rounded/000000/000000/ffffff/Roboto, sans-serif"
                                style={{ width: '100%', border: 'none', height: '1000px' }} />
                        </Box>
                    </Grid>


                    <Grid md={12} item>
                        <Typography variant="h5" component="h1">
                            WELCOME TO EXPRESS TRANSFER
                        </Typography>

                        <Box sx={{ py: 4, textAlign: 'left' }} >
                            <p><strong>Welcome to Express Transfer &ndash; Your Premier Airport Transfer Service in Wirral, Merseyside &amp; Cheshire!</strong></p>
                            <p>At Express Transfer, we specialize in providing reliable airport and seaport transfers across Wirral and Merseyside. With over 8 years of experience in the taxi industry, our founder has established a service that caters to all your travel needs. Whether you need a standard vehicle or a luxury executive transfer, we have you covered. Our premium fleet includes Mercedes V Class and Jet Class vehicles, ensuring an elevated travel experience. Quotes for luxury options are available upon request.</p>
                            <p><strong>Why Choose Express Transfer?</strong></p>
                            <ul>
                                <li><strong>DBS-Checked, Licensed Drivers:</strong> All our drivers are thoroughly background-checked and have valid licenses, ensuring your safety.</li>
                                <li><strong>Well-Maintained Vehicles:</strong> Our vehicles are always clean and regularly serviced to the highest standards.</li>
                                <li><strong>100+ Five-Star Google Reviews:</strong> We are proud to have earned more than 100 five-star ratings. At Express Transfer, you get nothing less than a 5-star experience every time.</li>
                            </ul>
                            <p><strong>Our Services Include:</strong></p>
                            <ul>
                                <li><strong>Airport &amp; Seaport Transfers:</strong> Fast and reliable transfers from Wirral to Liverpool John Lennon Airport, Manchester Airport, and beyond.</li>
                                <li><strong>Mercedes Executive &amp; Luxury Transfers:</strong> Travel in style with our premium vehicle options.</li>
                                <li><strong>Train &amp; Bus Station Services:</strong> On-time transfers to and from local stations.</li>
                                <li><strong>Corporate Executive Travel:</strong> Professional transport solutions tailored for business travelers.</li>
                                <li><strong>Sporting &amp; Race Day Events:</strong> Comfortable travel to Chester Races, Haydock, Aintree, and more.</li>
                                <li><strong>8-Seater Minibus Hire for Night Outs:</strong> Perfect for group events, including Stag &amp; Hen Parties and special occasions.</li>
                            </ul>
                            <p><strong>Dedicated Business Services:</strong></p>
                            <p>We cater to corporate clients with professional drivers who greet passengers at arrivals and provide a seamless, courteous experience. Our goal is to be an extension of your sales team, offering reliable transport for every business trip.</p>
                            <p><strong>&nbsp;</strong></p>
                            <p><strong>Services Available:</strong></p>
                            <ul>
                                <li>Executive airport transfers</li>
                                <li><strong>6-Seater Taxi transfers Near Me</strong></li>
                                <li><strong>Luxury Mercedes Vehicles for Comfort</strong></li>
                                <li><strong>7-Seater &amp; 8-Seater Taxi Hire Near Me:</strong> Ideal for larger groups.</li>
                                <li><strong>Airport Transfer Near Me</strong></li>
                                <li><strong>Wirral Airport Transfers:</strong> Fast, reliable transfers throughout the region.</li>
                                <li><strong>Wirral Minibus Hire:</strong> Available for airport transfers to Liverpool, Manchester, Gatwick, Heathrow, and events like Chester Races, Southport, Blackpool, and Cheltenham.</li>
                                <li><strong>Airport transfer from Bangor</strong></li>
                            </ul>
                            <p><strong>Welcome to Express Transfer &ndash; Your Premier Airport Transfer Service in Wirral, Merseyside &amp; Cheshire!</strong></p>
                            <p>At Express Transfer, we specialize in reliable airport and seaport transfers across Wirral and Merseyside. With over 8 years of taxi experience, our founder has created a service that caters to all your travel needs. Whether you require a standard vehicle or a luxury executive transfer, we&rsquo;ve got you covered with our premium fleet, including Mercedes V Class and Jet Class vehicles for an elevated travel experience. Quotes for these options are available upon request.</p>
                            <p><strong>Why Choose Express Transfer?</strong></p>
                            <p>We pride ourselves on delivering a seamless journey. Our drivers are DBS-checked, hold valid licenses, and undergo regular training to ensure the highest safety standards. All our vehicles are kept in pristine condition, regularly serviced, and cleaned thoroughly after every trip. We are committed to contacting you the day before your trip to confirm all details, ensuring everything runs smoothly.</p>
                            <p><strong>Customer Feedback</strong></p>
                            <p>We are proud to have over 100 five-star ratings on Google. Our commitment is simple: delivering a five-star experience every time.</p>

                        </Box>
                        { /* <GoogleReviews /> */}

                        <Box sx={{ py: 4, textAlign: 'left' }} >
                            <p><strong>Our Services Include:</strong></p>
                            <ul>
                                <li><strong>Airport &amp; Seaport Transfers:</strong> Fast and reliable transfers from Wirral to Liverpool John Lennon Airport, Manchester Airport, and beyond.</li>
                                <li><strong>Mercedes Executive &amp; Luxury Transfers:</strong> Travel in style with our premium options.</li>
                                <li><strong>Train &amp; Bus Station Services:</strong> Timely transfers to and from local stations.</li>
                                <li><strong>Corporate Executive Travel:</strong> Professional transport solutions for business travelers.</li>
                                <li><strong>Sporting &amp; Race Day Events:</strong> Comfortable travel to Chester Races, Haydock, Aintree, and more.</li>
                                <li><strong>8 Seater Minibus Hire for Night Outs:</strong> Perfect for groups, including Stag &amp; Hen Parties and special occasions.</li>
                            </ul>
                            <p><strong>Our Commitment to Business Clients</strong></p>
                            <p>We cater to business needs with professional drivers who greet passengers at arrivals with the utmost courtesy. We aim to be an extension of your sales team, ensuring a smooth and professional journey every step of the way.</p>
                            <p><strong>Services Available:</strong></p>
                            <ul>
                                <li><strong>6 Seater Taxi Near Me</strong></li>
                                <li><strong>Luxury Mercedes Vehicles for Your Comfort</strong></li>
                                <li><strong>7 Seater Taxi &amp; 8 Seater Taxi Hire Near Me:</strong> Ideal for larger groups, our minibuses ensure comfort for everyone.</li>
                                <li><strong>Airport Transfers from The Wirral, Liverpool, Merseyside, Cheshire</strong></li>
                                <li><strong>Wirral Airport Transfers:</strong> Fast, reliable transfers across the region.</li>
                                <li><strong>Wirral Minibus Hire:</strong> Available for airport transfers to Liverpool, Manchester, Gatwick, Heathrow, and events like Chester Races, Southport, Blackpool, and Cheltenham.</li>
                                <li><strong>Executive Mercedes Estate and Standard Vehicles</strong></li>
                                <li><strong>Long Journey Taxi Services</strong></li>
                                <li><strong>Business events</strong></li>
                            </ul>
                            <p><strong>For Competitive Prices and Excellent Service, Choose Express Transfer!</strong></p>
                            <p>Request your quote and book your transfer with us today. Let Express Transfer make your journey smooth and stress-free.</p>
                            <p>&nbsp;</p>

                        </Box>

                    </Grid>

                </Grid>

            </Box>

        </>
    );
}

export default Home;